import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

import { Form, FormItem, Input, Modal, message, Button, } from 'ant-design-vue'
import { reactive, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const valid = ref(false);
const open = ref<boolean>(true);
const loading = ref<boolean>(false);
const formState = reactive({
  keyword: '',
})
const handleOk = () => {
  if (formState.keyword !== '101101') {
    message.error('密碼錯誤')
    return
  }
  valid.value = true;
  handleCancel();
};
const handleCancel = () => {
  open.value = false;
}


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (valid.value)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_unref(Modal), {
      open: open.value,
      "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((open).value = $event)),
      title: "驗證身份",
      onOk: handleOk
    }, {
      footer: _withCtx(() => [
        _createVNode(_unref(Button), {
          key: "submit",
          type: "primary",
          loading: loading.value,
          onClick: handleOk
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("確定")
          ])),
          _: 1
        }, 8, ["loading"])
      ]),
      default: _withCtx(() => [
        _createVNode(_unref(Form), null, {
          default: _withCtx(() => [
            _createVNode(_unref(FormItem), {
              label: "密碼",
              name: "keyword"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Input), {
                  value: formState.keyword,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.keyword) = $event)),
                  maxlength: 100,
                  placeholder: "請輸入密碼"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}
}

})